import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { DialogService } from '../../../services/dialog.service';
import { ClaimRewardComponent } from './claim-reward.component';

@Component({
  selector: 'daily-reward',

  imports: [CommonModule],
  template: `
    <div class="grid place-items-center">
      @if(isRewardAvailable()) {
      <img
        src="/icons/chest-available.webp"
        alt="daily-reward"
        class="h-[24px] hover:cursor-pointer"
        (click)="openClaimModal()"
        tabindex="0"
      />
      } @else {
      <img
        src="/icons/chest-claimed.webp"
        class="h-[24px] hover:cursor-pointer"
        (click)="openClaimModal()"
        alt="daily-claimed"
        tabindex="0"
      />
      }
    </div>
  `,
})
export class DailyRewardComponent {
  isRewardAvailable = signal(false);

  dialogService = inject(DialogService);

  openClaimModal() {
    this.dialogService.openDialog(ClaimRewardComponent, {
      data: {
        isRewardAvailable: this.isRewardAvailable(),
      },
    });
  }
}
