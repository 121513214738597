import { CommonModule } from '@angular/common';
import { Component, computed, inject, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslatePipe } from '@codeandweb/ngx-translate';
import { map } from 'rxjs';
import { MetaService } from '../../../services/meta.service';
import { ServiceProviderPlatformComponent } from '../../service-provider-platform/service-provider-platform.component';
import { SiteInfoComponent } from '../../site-info/site-info.component';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [
    `
      .footer-content {
        font-size: 14px;

        h1 {
          font-size: 36px;
          font-weight: 700;
          color: #3f3d41;
        }

        h2 {
          font-size: 30px;
          font-weight: 500;
          color: #3f3d41;
        }

        h3 {
          font-size: 24px;
          font-weight: 500;
          color: #b8c5d5;
        }

        p {
          font-size: 12px;
          margin-top: 15px;
        }
      }
    `,
  ],
  imports: [
    CommonModule,
    ServiceProviderPlatformComponent,
    TranslatePipe,
    SiteInfoComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  metaService = inject(MetaService);
  sanitizer = inject(DomSanitizer);
  router = inject(Router);
  seoContent = toSignal(this.metaService.getAllSEOContent());
  footerContent = toSignal(
    this.metaService.getSetting().pipe(map((res) => res.data?.footerContent))
  );
  titles = [
    { displayName: 'Pusat Bantuan', routeName: 'help-center' },
    { displayName: 'Tentang OPERA5000', routeName: 'about-us' },
    { displayName: 'Syarat dan Ketentuan', routeName: 'terms-and-conditions' },
    { displayName: 'Responsible Gambling', routeName: 'responsible-gambling' },
  ];
  footerHtml = computed(() => {
    const content = this.footerContent();
    return content ? this.sanitizer.bypassSecurityTrustHtml(content) : '';
  });
  tabClicked(index: number): void {
    const url = [`http://localhost:4200/${this.titles[index].routeName}`];
    this.router.navigate(url);
  }
}
