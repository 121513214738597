import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'marketing-popup',

  imports: [CommonModule, ButtonComponent],
  templateUrl: './marketing-popup.component.html',
})
export class MarketingPopupComponent {
  popup = input<any>();
}
