<div class="bg-primary-700 w-full border-b-border-devider border-b-4">
  <div class="flex md:flex-row flex-col items-center gap-4 container mx-auto">
    @if(logo()) {
    <img tabindex="0"
      [src]="$any(logo())"
      width="180"
      height="60"
      class="ml-4 cursor-pointer"
      (click)="router.navigateByUrl('/')"
      alt="logo" />}
    <div class="flex ml-auto overflow-x-auto w-full">
      @for(item of gameCategories(); track $index) {
      <div tabindex="0"
        class="flex flex-col items-center justify-center p-2 py-5 rounded hover:cursor-pointer text-[#adbed2] menu-game min-w-[77px]"
        (click)="router.navigateByUrl(item.urlPath)"
        (mouseover)="handleMouseOver($event, op, item.providers)">
        <img [src]="item.imageUrl"
          [alt]="item.title"
          [width]="30" />
        <span class="text-white text-[12px] mt-2 whitespace-nowrap uppercase">{{
          item.title | translate
          }}</span>
      </div>

      }

      <p-overlayPanel #op
        styleClass="mt-0 w-screen [&_.p-overlaypanel-content]:p-0"
        appendTo="body">
        <div class="bg-primary-500 w-full border-special"
          (mouseout)="op.hide()"
          (mouseover)="op.show($event)">
          <div
            class="bg-black/10 grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-4 grid-cols-3 gap-4 p-4">
            @for(i of selectedCategory(); track $index) {
            <div tabindex="{{ ($index + 1) * 10 }}"
              class="flex flex-col items-center rounded-full border-white border overflow-hidden w-[220px] h-[110px] relative">
              <img tabindex="{{ ($index + 1) * 10 }}"
                [src]="i.image"
                [alt]="i.providerCode"
                width="100%"
                class="hover:cursor-pointer"
                (click)="handleClick(i)"
                [ngClass]="{
                  'opacity-50 grayscale hover:cursor-default': i.isMaintenance
                }" />

              @if(i.isMaintenance) {
              <div class="absolute top-0 left-0 w-full h-full bg-black/50 text-white grid place-items-center">
                <span class="bg-gray-400 px-2 rounded">{{
                  "MAINTENANCE" | translate
                  }}</span>
              </div>
              }
            </div>
            }
          </div>
        </div>
      </p-overlayPanel>
    </div>
  </div>
</div>