import { Route } from '@angular/router';
import { MainLayoutComponent } from '../components/main-layout/main-layout.component';
import { playerInfoGuard } from '../guards/player-info.guard';
import { transactionGuard } from '../guards/transaction.guard';
import { isLogin } from './isLogin.guard';
export const routes: Route[] = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('../pages/home-page/home-page.component').then(
            (m) => m.HomePageComponent
          ),
      },
      {
        path: 'register',
        loadComponent: () =>
          import(
            '../pages/account-center/register/register-page.component'
          ).then((m) => m.RegisterPageComponent),
      },
      {
        path: 'about-us',
        loadComponent: () =>
          import('../components/home-page/desktop/desktop.component').then(
            (m) => m.DesktopComponent
          ),
      },
      {
        path: 'help-center',
        loadComponent: () =>
          import('../components/home-page/desktop/desktop.component').then(
            (m) => m.DesktopComponent
          ),
      },
      {
        path: 'term-condition',
        loadComponent: () =>
          import('../components/home-page/desktop/desktop.component').then(
            (m) => m.DesktopComponent
          ),
      },
      {
        path: 'responsible-gambling',
        loadComponent: () =>
          import('../components/home-page/desktop/desktop.component').then(
            (m) => m.DesktopComponent
          ),
      },
      {
        path: 'forgot-password',
        loadComponent: () =>
          import('../pages/forgot-password/forgot-password.component').then(
            (m) => m.ForgotPasswordComponent
          ),
      },
      {
        path: 'account-center',
        canActivate: [isLogin, playerInfoGuard],
        loadComponent: () =>
          import('../pages/account-center/account-center.component').then(
            (m) => m.AccountCenterComponent
          ),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'my-account',
          },
          {
            path: 'my-account',
            loadComponent: () =>
              import(
                '../pages/account-center/my-account-page/my-account-page.component'
              ).then((m) => m.MyAccountPageComponent),
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'account-summary',
              },
              {
                path: 'account-summary',
                data: {
                  title: 'My Account',
                },
                loadComponent: () =>
                  import('../components/my-account/my-account.component').then(
                    (m) => m.MyAccountComponent
                  ),
              },
              {
                path: 'change-password',
                data: {
                  title: 'Change Password',
                },
                loadComponent: () =>
                  import('../pages/account-center/change-password/change-password.component').then(
                    (m) => m.ChangePassWordComponent
                  ),
              },
            ],
          },
          {
            path: 'bank',
            canActivate: [transactionGuard],
            loadComponent: () =>
              import(
                '../pages/account-center/banking-page/banking-page.component'
              ).then((m) => m.BankingPageComponent),
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'deposits',
              },
              {
                path: 'deposits',
                data: {
                  title: 'Deposits',
                },
                loadComponent: () =>
                  import(
                    '../pages/account-center/banking-page/deposit/deposit.component'
                  ).then((m) => m.DepositComponent),
              },
              {
                path: 'withdrawals',
                data: {
                  title: 'Withdrawals',
                },
                loadComponent: () =>
                  import(
                    '../pages/account-center/banking-page/withdrawal/withdrawal.component'
                  ).then((m) => m.WithdrawalComponent),
              },
            ],
          },
          {
            path: 'loyalty-reward',
            loadComponent: () =>
              import(
                '../pages/account-center/loyalty-reward-page/loyalty-reward-page.component'
              ).then((m) => m.LoyaltyRewardComponent),
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'event-calendar',
              },
              {
                path: 'event-calendar',
                data: {
                  title: 'Event Calendar',
                },
                loadComponent: () =>
                  import(
                    '../components/loyalty-reward-page/event-calendar/event-calendar.component'
                  ).then((m) => m.EventCalendarComponent),
              },
              {
                path: 'missions',
                data: {
                  title: 'Missions',
                },
                loadComponent: () =>
                  import(
                    '../components/loyalty-reward-page/missions/missions.component'
                  ).then((m) => m.MissionsComponent),
              },
            ],
          },
        ],
      },
      {
        path: 'slots',
        loadComponent: () =>
          import('../pages/providers/slots/slots.component').then(
            (m) => m.SlotsComponent
          ),
      },
      {
        path: ':providers',
        loadComponent: () =>
          import('../pages/providers/providers.component').then(
            (m) => m.ProvidersComponent
          ),
      },
      {
        path: ':providers/:providerCode',
        loadComponent: () =>
          import('../pages/provider/provider.component').then(
            (m) => m.ProviderComponent
          ),
      },
    ],
  },
  {
    path: 'dispatch',
    pathMatch: 'prefix',
    children: [
      {
        path: 'iframe/:providerCode',
        loadComponent: () =>
          import('../pages/iframe/iframe.component').then(
            (m) => m.IframeComponent
          ),
      },
      {
        path: '**',
        loadComponent: () =>
          import('../pages/dispatch/dispatch.component').then(
            (m) => m.DispatchComponent
          ),
      },
    ],
  },
];
