<div class="bg-primary-0">
  <div class="flex flex-col justify-center container m-auto min-h-[70px]">
    <div class="flex justify-between">
      <toggle-language />
      @if(isLogin() !== null) {
      <div class="flex gap-2 p-2 ml-auto">
        @if(isLogin()) {
        <div class="flex gap-2 items-center bg-primary-800 px-2 rounded">
          <player-info />
          <credit-balance />
          <daily-reward />
        </div>
        <img
          tabindex="0"
          (click)="goToPage('account-center/my-account')"
          src="/icons/user.svg"
          alt="avt"
          class="w-8 bg-white/90 rounded p-2 hover:cursor-pointer"
        />
        <img
          tabindex="1"
          (click)="goToPage('account-center/bank')"
          src="/icons/bank.svg"
          href="/account-center/bank"
          alt="avt"
          class="w-8 bg-white/90 rounded p-2 hover:cursor-pointer"
        />
        <div
          tabindex="2"
          class="w-8 bg-button-danger rounded p-2 hover:cursor-pointer"
          (click)="logout()"
        >
          <core-icon class="bg-white" path="/icons/logout.svg" />
        </div>
        } @else {

        <core-text-field
          [(ngModel)]="user().userName"
          placeholder="Username"
        ></core-text-field>
        <div class="grid grid-cols-1 gap-1">
          <core-password
            [(ngModel)]="user().password"
            placeholder="Password"
          ></core-password>
          <a class="text-primary-550" href="/forgot-password">{{
            "FORGOT_PASSWORD" | translate
          }}</a>
        </div>
        <core-client-button
          class="[&_button]:min-h-[unset]"
          label="Login"
          type="outline"
          (click)="login()"
        />
        <core-client-button
          class="[&_button]:min-h-[unset]"
          (click)="goToPage('register')"
          label="Register"
        />

        }
      </div>
      }
    </div>
  </div>
</div>
