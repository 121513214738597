import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@codeandweb/ngx-translate';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'toggle-language',
  template: `
    <div class="h-full grid place-items-center">
      <p-dropdown
        [options]="countries"
        [formControl]="selectedCountry"
        optionLabel="name"
        styleClass="[&_.p-dropdown-trigger]:hidden !border-none"
      >
        <ng-template pTemplate="selectedItem">
          @if(selectedCountry) {
          <div class="flex align-items-center gap-2">
            <img
              src="/icons/{{ selectedCountry.value?.icon }}"
              alt="flag"
              style="width: 18px"
            />
          </div>
          }
        </ng-template>
        <ng-template let-country pTemplate="item">
          <div
            class="flex align-items-center gap-2 w-[100px]"
            (click)="changeLanguage(country.code)"
            tabindex="0"
          >
            <img
              src="/icons/{{ country.icon }}"
              alt="flag"
              style="width: 18px"
            />
            <div class="grid grid-cols-1">
              <p class="text-sm">{{ country.name }}</p>
              <p class="text-xs">{{ country.engName }}</p>
            </div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  `,
  imports: [DropdownModule, CommonModule, ReactiveFormsModule],
})
export class ToggleLanguageComponent {
  private translateService = inject(TranslateService);
  countries: any[] | undefined = [
    { name: 'English', code: 'EN', icon: 'eng-flag.svg', engName: 'English' },
    {
      name: 'BHS Indonesia',
      code: 'ID',
      icon: 'indo-flag.svg',
      engName: 'Indonesian',
    },
  ];

  selectedCountry = new FormControl(
    this.countries?.find(
      (country) =>
        country.code.toLowerCase() ===
        (
          this.translateService.currentLang || this.translateService.defaultLang
        )?.toLowerCase()
    )
  );

  changeLanguage(code: string) {
    this.translateService.use(code.toLowerCase()).subscribe(() => {
      this.selectedCountry.setValue({
        name: this.countries?.find((country) => country.code === code)?.name,
        code: code,
        icon: this.countries?.find((country) => country.code === code)?.icon,
      });
    });
  }
}
