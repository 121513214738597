<div class="bg-black/50 pt-4 pb-8 mt-4">
  <div class="container mx-auto">
    <div class="flex flex-wrap justify-between">
      <div class="flex flex-wrap items-center space-x-2 mb-[15px]">
        @for(title of titles; track $index) {
        <div
          class="flex items-center border-r border-r-[#ccc] pr-2.5 last:border-r-0"
        >
          <h2
            class="font-semibold hover:text-text-100 cursor-pointer"
            (click)="tabClicked($index)"
          >
            {{ title.displayName }}
          </h2>
        </div>
        }
      </div>
      <p class="basis-full lg:basis-auto text-end mb-[15px]">
        {{ "COPYRIGHT" | translate }}
      </p>
    </div>
    <div class="footer-content" [innerHTML]="footerHtml()"></div>
    <app-service-provider-platform />
    <app-site-info></app-site-info>
  </div>
</div>
