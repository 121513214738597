import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { HomepageService } from '../../services/homepage.service';

@Component({
  selector: 'app-service-provider-platform',
  templateUrl: './service-provider-platform.component.html',
  imports: [CommonModule],
})
export class ServiceProviderPlatformComponent {
  homepageService = inject(HomepageService);
  providerImages = toSignal(this.homepageService.getProviderImage());
}
